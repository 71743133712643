import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { useParams } from "react-router-dom";
import { postData } from "../../api";
import LeftTop from "../../assets/images/left-top.png";
import LeftBottom from "../../assets/images/left-bottom.png";
import RightTop from "../../assets/images/right-top.png";
import RightBottom from "../../assets/images/right-bottom.png";
import Group from "../../assets/images/Group.png";
import GooglePlay from "../../assets/images/GooglePlay.png";

const SetPassword = () => {
  let { id } = useParams();
  const [validated, setValidated] = useState(false);
  const [passMatch, setPassMatch] = useState(true);
  const [successMsg, setSuccessMsg] = useState();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    password: "",
    cPassword: "",
  });
  useEffect(() => {
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateToken = async () => {
    
    setLoading(true);
    let obj = {
      secret_key: id,
    };
    try {
      const getData = await postData("/check-content-creator-onboard", {}, obj);
      if (getData && getData.status === 1) {
        setLoading(false);
        setSuccessMsg(false);
      } else {
        setSuccessMsg(true);
      }
    } catch (err) {}
  };

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let passwordMatch = true;
    passwordMatch =
      event.target.password.value === event.target.cPassword.value
        ? true
        : false;
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (passwordMatch === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true && passwordMatch) {
      createpassword();
    }
    setPassMatch(passwordMatch);
  };

  const createpassword = async () => {
    setLoading(true);
    let obj = {
      secret_key: id,
      password: state.cPassword,
    };
    try {
      const getData = await postData("/content-creator-onboard", {}, obj);
      if (getData && getData.status === 1) {
        setLoading(false);
        setSuccessMsg(true);
      } else {
        setLoading(false);
      }
    } catch (err) {}
  };

  return (
    <section className="setpassword-section">
      <img
        className="position-absolute top-0 start-0"
        src={LeftTop}
        alt="crunch"
      />
      <img
        className="position-absolute bottom-0 start-0"
        src={LeftBottom}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 topcircle"
        src={RightTop}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 bottomcircle"
        src={RightBottom}
        alt="crunch"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="login-box">
              <div className="content">
                <div className="img mb-5 text-center">
                  <img src={logo} alt="logo" />
                </div>
                {successMsg === false ? (
                  <>
                    <h5 className="pb-1 fw-700 text-center"> Hello there, </h5>
                    <p className="fw-500 f-16 mb-3 mt-1 text-center">
                      you've been invited to Crunch. Create a password to
                      proceed further.
                    </p>
                    <Form
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <Input
                            // label=" Email"
                            required
                            type="password"
                            name="password"
                            size="lg"
                            placeholder="New password"
                            errorMessage={
                              state.password === ""
                                ? "Please enter new password"
                                : "Enter Minimum 8 Characters"
                            }
                            pattern=".{8,}"
                            min={8}
                            onChange={handleInput}
                            isInvalid={
                              state.password.length && state.password.length < 7
                                ? true
                                : false
                            }
                          ></Input>
                          <Input
                            // label="Password"
                            required
                            type="password"
                            name="cPassword"
                            size="lg"
                            // disabled={
                            //     state.password.length > 7 ? false : true
                            // }
                            placeholder="Confirm password"
                            errorMessage={
                              passMatch === false
                                ? "Password Doesn't Match"
                                : "Please fill this field"
                            }
                            isInvalid={
                              passMatch === false && state.password.length > 7
                                ? true
                                : false
                            }
                            onChange={handleInput}
                          ></Input>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-1"></div>
                        <div className="col-md-10">
                          <Button
                            type="submit"
                            disabled={loading}
                            loading={loading}
                            className="btn-primary text-white d-block w-100"
                          >
                            Create password
                          </Button>
                        </div>
                        <div className="col-md-1"></div>
                      </div>
                    </Form>
                  </>
                ) : (
                  <>
                    <h5 className="pb-1 fw-700 text-center"> Awesome! </h5>
                    <p className="fw-500 f-16 mb-3 mt-1 text-center">
                      Now that you've created the password
                    </p>
                    <h6 className="fw-600 f-17 mb-3 mt-5 text-center">
                      Go ahead and download the Crunch app to get started.
                    </h6>
                    <div className="d-flex mt-4 Applink">
                      <a
                        className="me-3"
                        href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada"
                      >
                        <img src={Group} alt="group" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada">
                        <img src={GooglePlay} alt="play" />
                      </a>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </section>
  );
};

export default SetPassword;
