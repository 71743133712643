import { useEffect, useState } from "react";
import {postData } from "../../api";
import Form from 'react-bootstrap/Form';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Plugin
} from 'chart.js';
import { Bar,Line,Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import "./style.scss";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = () => {
  const [userChartData, setUserChartData] = useState({
    labels:[],
    datasets:[]
  });
  const [userChartFilter,setUserChartFilter]=useState({
    type:'day'
  });
  const [transactionChartData, setTransactionChartData] = useState({
    labels:[],
    datasets:[]
  });
  const [transactionChartFilter,setTransactionChartFilter]=useState({
    type:'month'
  });
  const [userRetentionChartData, setUserRetentionChartData] = useState({
    labels:[],
    datasets:[]
  });
  const [userRetentionChartFilter,setuserRetentionChartFilter]=useState({
    type:'month'
  });
  const [subscriptionChartData, setSubscriptionChartData] = useState({
    labels:[],
    datasets:[]
  });
  const [subscriptionChartFilter,setSubscriptionChartFilter]=useState({
    type:'month'
  });
  const [sessionChartData, setSessionChartData] = useState({
    labels:[],
    datasets:[]
  });
  const [sessionChartFilter,setSessionChartFilter]=useState({
    type:'month'
  });
  const piechartFilterOptions=['month','overall'];
  
      const userChartoptions = {
        responsive: true,
        plugins: {
          datalabels:{},
          tooltips:{
            enabled:false
          },
          legend: {
            position: 'bottom',
          },
          title: {
            display: false,
          },
        },
        scales:{
          y:{
           title:{
            display:true,
            text:"Rate"
           }
          },
          x:{
            title:{
             display:true,
             text: userChartFilter.type.charAt(0).toUpperCase() + userChartFilter.type.slice(1)
            }
           }
        }
      };
      const transactionsChartoptions = {
        responsive: true,
        plugins: {
          legend: {
            display:false,
            position: 'bottom',
          },
          title: {
            display: false,
          },
        },
        scales:{
          y:{
           title:{
            display:true,
            text:"Amount in dollars"
           }
          },
          x:{
            title:{
             display:true,
             text:  transactionChartFilter.type.charAt(0).toUpperCase() + transactionChartFilter.type.slice(1)
            }
           }
        }
      };
      const userRetentionChartoptions = {
        responsive: true,
        plugins: {
          legend: {
            display:false,
            position: 'bottom',
          },
          title: {
            display: false,
          },
        },
        scales:{
          y:{
           title:{
            display:true,
            text:"Percentage of user retention"
           }
          },
          x:{
            title:{
             display:true,
             text:  userRetentionChartFilter.type.charAt(0).toUpperCase() + userRetentionChartFilter.type.slice(1)
            }
           }
        }
      };
      const pieChartOptions={
          responsive: true,
          plugins: {
            tooltips: {
              enabled: false
            },
            legend: {
              position: 'bottom',
            },
            title: {
              display: false
            },
            datalabels: {
              display: function(context) {
                return context.dataset.data[context.dataIndex] !== 0; // or >= 1 or ...
             },
              formatter: (value, ctx) => {
                  let sum = 0;
                  let dataArr = ctx.chart.data.datasets[0].data;
                  dataArr.map(data => {
                      sum += data;
                  });
                  console.log(value)
                  let percentage = value+"%";
                  return percentage;
              },
              color: '#fff',
          }

        }  
      }

  useEffect(() => {
    getUserChartData();
  }, [userChartFilter.type]);
  
  useEffect(() => {
    getTransactionChartData();
  }, [transactionChartFilter.type]);
  useEffect(() => {
    getUserRetentionChartData();
  }, [userRetentionChartFilter.type]);
  useEffect(() => {
    getSubscriptionChartData();
  }, [subscriptionChartFilter.type]);
  useEffect(() => {
    getUserSessionChartData();
  }, [sessionChartFilter.type]);
  const userChartfilterChange = (e) => {
    const value = e.target.value;
    setUserChartFilter({type:value})
  };
  
  const transactionChartfilterChange = (e) => {
    const value = e.target.value;
    setTransactionChartFilter({type:value})
  };
  const userRetentionChartfilterChange = (e) => {
    const value = e.target.value;
    setuserRetentionChartFilter({type:value})
  };
  const subscriptionChartfilterChange = (e) => {
    const value = e.target.value;
    setSubscriptionChartFilter({type:value})
  };
  const sessionChartfilterChange = (e) => {
    const value = e.target.value;
    setSessionChartFilter({type:value})
  };
  const getUserChartData = async()=> {
    try {
      const getData = await postData("/user-statistics", {},userChartFilter);
      if (getData && getData.status === 1) {
       setUserChartData(getData.data);
      }
    } catch (err) {}
  }
  const getTransactionChartData = async()=> {
    try {
      const getData = await postData("/transactions-statistics", {},transactionChartFilter);
      if (getData && getData.status === 1) {
        setTransactionChartData(getData.data);
      }
    } catch (err) {}
  }
  const getUserRetentionChartData = async()=> {
    try {
      const getData = await postData("/user-retention", {},userRetentionChartFilter);
      if (getData && getData.status === 1) {
        setUserRetentionChartData(getData.data);
      }
    } catch (err) {}
  }
  const getSubscriptionChartData = async()=> {
    try {
      const getData = await postData("/percentage-subscription-plans", {},subscriptionChartFilter);
      if (getData && getData.status === 1) {
        setSubscriptionChartData(getData.data);
      }
    } catch (err) {}
  }
  const getUserSessionChartData = async()=> {
    try {
      const getData = await postData("/session-length-statistics", {},sessionChartFilter);
      if (getData && getData.status === 1) {
        setSessionChartData(getData.data);
      }
    } catch (err) {}
  }
 
    return (
        <div>
           <h4 className="title mb-3">Statictics</h4>
          <div className='row'>
            <div className='col-md-12 mb-4'>
              <div className='statictis_card'>
                  <div className="row">
                    <div className="col-md-9">
                    <h4 className='statictis_title'>Users</h4>
                    </div>
                    <div className="col-md-3">
                  
                      <Form.Select 
                      aria-label="Default select example"
                      name="type"
                      value={userChartFilter.type}
                      onChange={(e) => userChartfilterChange(e)}
                      >
                        <option value="day">Days</option>
                        <option value="month">Months</option>
                        <option value="year">Years</option>
                      </Form.Select>
                    </div>
                  </div>
                  <Bar options={userChartoptions} data={userChartData} />
              </div>
            </div>
            <div className='col-md-12 mb-4'>
              <div className='statictis_card'>
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className='statictis_title'>Transactions</h4>
                    </div>
                    <div className="col-md-3">
                    <Form.Select 
                      aria-label="Default select example"
                      name="type"
                      value={transactionChartFilter.type}
                      onChange={(e) => transactionChartfilterChange(e)}
                      >
                        <option value="month">Months</option>
                        <option value="year">Years</option>
                      </Form.Select>
                    </div>
                  </div>
                  <Line options={transactionsChartoptions} data={transactionChartData} />
              </div>
            </div>
            <div className='col-md-12 mb-4'>
              <div className='statictis_card'>
                  <div className="row">
                    <div className="col-md-9">
                      <h4 className='statictis_title'>User retention</h4>
                    </div>
                    <div className="col-md-3">
                    <Form.Select 
                      aria-label="Default select example"
                      name="type"
                      value={userRetentionChartFilter.type}
                      onChange={(e) => userRetentionChartfilterChange(e)}
                      >
                        <option value="month">Months</option>
                        <option value="year">Years</option>
                      </Form.Select>
                    </div>
                  </div>
                  <Line options={userRetentionChartoptions} data={userRetentionChartData} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='statictis_card'>
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className='statictis_title'>Subscription plans</h4>
                    </div>
                    <div className="col-md-4">
                    <Form.Select 
                      aria-label="Default select example"
                      name="type"
                      value={subscriptionChartFilter.type}
                      onChange={(e) => subscriptionChartfilterChange(e)}
                      >
                      {piechartFilterOptions &&
                        piechartFilterOptions.map((filter) => (
                          <option key={filter} value={filter}>
                            {filter}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  <Pie options={pieChartOptions}  plugins={[ChartDataLabels]} data={subscriptionChartData} />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='statictis_card'>
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className='statictis_title'>Session length</h4>
                    </div>
                    <div className="col-md-4">
                    <Form.Select 
                      aria-label="Default select example"
                      name="type"
                      value={sessionChartFilter.type}
                      onChange={(e) => sessionChartfilterChange(e)}
                      >
                      {piechartFilterOptions &&
                        piechartFilterOptions.map((filter) => (
                          <option key={filter} value={filter}>
                            {filter}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  </div>
                  <Pie options={pieChartOptions}  plugins={[ChartDataLabels]} data={sessionChartData} />
              </div>
            </div>
          </div>
            {/* <Graph height={320} options={options} series={series} type={'bar'} />
            <Graph height={320} options={options1} series={series1} type={'donut'} width={280} /> */}
            {/* <Pie data={data} /> */}
        </div>
    );
}
export default Statistics