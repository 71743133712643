import React from "react";
import FormModal from "../../components/Modal";
const UsersReport = ({ show, onClose, countDetail }) => {
  return (
    <FormModal show={show} onClose={onClose} heading="Reported users" size="lg">
      {countDetail.users && countDetail.users.length && (
        <div className="row form-group">
          <div className="col-md-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {countDetail.users &&
                  countDetail.users.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.user_data?.name}</td>
                      <td>{item?.user_data?.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default UsersReport;
