import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Manage = ({ show, onClose, singleCreator }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [subscription] = useState([
  //   {
  //     name: "10$",
  //     id: "10",
  //   },
  //   {
  //     name: "20$",
  //     id: "20",
  //   },
  // ]);
  const [creatorObject, setCreatorObject] = useState({
    name: "",
    email: "",
    user_name: "",
    profile: {
      phone_no: "",
      bio: "",
      subscription: "",
    },
  });

  const handleInput = (e, whichField) => {
    const value = e.target.value;
    const name = e.target.name;
    if (whichField) {
      setCreatorObject((prev) => ({
        ...prev,
        profile: {
          ...prev.profile,
          [name]: value,
        },
      }));
    } else {
      setCreatorObject((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveClick();
    }
  };
  const saveClick = async () => {
    setLoading(true);
    const res = await postData("/admin-user-update", {}, creatorObject);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (singleCreator) {
      setCreatorObject(singleCreator);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cancelClick = () => {
    onClose(false);
  };
  return (
    <FormModal show={show} onClose={onClose} heading="Edit profile information">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Input
          label="Name"
          type="text"
          name="name"
          value={creatorObject.name}
          size="lg"
          placeholder="Enter name"
          errorMessage="Name is required"
          onChange={handleInput}
        ></Input>
        <Input
          label="Username"
          type="text"
          name="user_name"
          value={creatorObject.user_name}
          size="lg"
          placeholder="Enter username"
          errorMessage="Username is required"
          onChange={handleInput}
          disabled="disabled"
        ></Input>
        <Input
          label="Email"
          type="email"
          name="email"
          value={creatorObject.email}
          size="lg"
          placeholder="Enter email"
          errorMessage="Email is required"
          onChange={handleInput}
          disabled="disabled"
        ></Input>
        <TextArea
          label="About me"
          name="bio"
          value={creatorObject.profile.bio}
          size="lg"
          placeholder="Enter about me"
          errorMessage="About me is not valid"
          onChange={(e) => handleInput(e, true)}
        ></TextArea>
        {/* <Select
          label="Subscription"
          name="subscription"
          value={creatorObject.profile.subscription}
          options={subscription}
          size="lg"
          errorMessage="Subscription is not valid"
          onChange={(e) => handleInput(e, true)}
        ></Select> */}
        <div className="text-end">
          <Button
            type="button"
            className="btn-default text-blacksix me-3"
            onClick={cancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            Update
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default Manage;
