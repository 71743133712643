import React, { useState, useEffect } from "react";
import { postData } from "../../api";
import Accordion from "react-bootstrap/Accordion";
import Loader from "../../components/Loader";
import Manage from "./Manage";
import Delete from "./Delete";

export const FaqLists = ({
  whichTab,
  onclose,
  addShowModal,
  setAddShowModal,
}) => {
  const [faqList, setFaqList] = useState([]);
  const [initLoading, setInitLoading] = useState(false);
  const [singleFaq, setSingleFaq] = useState(null);
  const [deleteShowModal, setDeleteShowModal] = useState(false);

  const onAddClose = (status) => {
    if (status) getFaqLists();
    setSingleFaq(null);
    // onclose();
    setAddShowModal(false);
    setDeleteShowModal(false);
  };

  const editClick = (row) => {
    setAddShowModal(true);
    setSingleFaq(row.userFaqItem);
  };
  const deleteClick = (row) => {
    setDeleteShowModal(true);
    setSingleFaq(row.userFaqItem);
  };

  const getFaqLists = async () => {
    setInitLoading(true);
    let obj = {
      length: "50",
      start: (1 - 1) * "50",
      draw: "50",
      role: whichTab,
    };
    const res = await postData("/admin-get-faqs", {}, obj);
    if (res.status === 1) {
      // setCommonData(res.data);
      setFaqList(res.data.faqs);
      setInitLoading(false);
    } else if (res.statusCode === 422) {
      setInitLoading(false);
    } else {
      setInitLoading(false);
    }
  };
  useEffect(() => {
    getFaqLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Accordion defaultActiveKey="0">
        {faqList.map((userFaqItem, userFaqKey) => {
          return (
            <Accordion.Item key={userFaqKey} eventKey={userFaqKey}>
              <Accordion.Header>
                {userFaqItem.question}
                <i className="fa fa-chevron-down"></i>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  className="privacy-wrapper"
                  dangerouslySetInnerHTML={{ __html: userFaqItem.answer }}
                ></div>
                <div className="mt-3 text-end">
                  <button
                    className="btn btn-md btn-default text-blacksix f-14 fw-600 me-3"
                    onClick={() => editClick({ userFaqItem })}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-md btn-primary text-white f-14 fw-600"
                    onClick={() => deleteClick({ userFaqItem })}
                  >
                    Delete
                  </button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>

      {addShowModal && (
        <Manage
          show={addShowModal}
          onClose={onAddClose}
          singleFaq={singleFaq}
          whichTab={whichTab}
        />
      )}
      {deleteShowModal && (
        <Delete
          show={deleteShowModal}
          onClose={onAddClose}
          singleFaq={singleFaq}
          whichTab={whichTab}
        />
      )}
      {initLoading && <Loader />}
    </div>
  );
};
