import { BrowserRouter, Routes, Route } from "react-router-dom";
//pages
import Login from "./pages/Login";
import DashBoard from "./pages/Dashboard";
import Users from "./pages/Users";
import ContentCreators from "./pages/ContentCreators";
import InviteStatus from "./pages/InviteStatus";
import Layout from "./layout";
import "./App.scss";
import Interest from "./pages/Interest";
import Statistics from "./pages/Statistics";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Settings from "./pages/Settings";
import Notifications from "./pages/Notifications";
import Faq from "./pages/Faq";
import AddNew from "./pages/Faq/AddNew";
import SetPassword from "./pages/Createpassword";
import Toast from "./components/Toast";
import Payment from "./pages/Payment";
import Report from "./pages/Report";
import TopCreatorList from "./pages/Dashboard/TopCreatorList";
import CreatorOnboard from "./pages/CreatorOnboard";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" exact={true} element={<Login />} />
          <Route
            path="/setpassword/:id"
            exact={true}
            element={<SetPassword />}
          />
           <Route
            path="/creatoronboard/:id"
            exact={true}
            element={<CreatorOnboard />}
          />
          <Route path="/" element={<Layout />}>
            <Route path="/" exact={true} element={<Users />} />
            <Route path="dashBoard" exact={true} element={<DashBoard />} />
            <Route path="topcreators" exact={true} element={<TopCreatorList />} />
            <Route path="users" exact={true} element={<Users />} />
            <Route
              path="contentcreators"
              exact={true}
              element={<ContentCreators />}
            />
            <Route
              path="users/inviteStatus"
              exact={true}
              element={<InviteStatus />}
            />
            <Route
              path="contentcreators/inviteStatus"
              exact={true}
              element={<InviteStatus />}
            />
            <Route path="interest" exact={true} element={<Interest />} />
            <Route path="statistics" exact={true} element={<Statistics />} />
            <Route path="payments" exact={true} element={<Payment />} />
            <Route path="report" exact={true} element={<Report />} />
            <Route path="privacy" exact={true} element={<PrivacyPolicy />} />
            <Route
              path="termsConditions"
              exact={true}
              element={<TermsConditions />}
            />
            <Route path="settings" exact={true} element={<Settings />} />
            <Route
              path="notifications"
              exact={true}
              element={<Notifications />}
            />
            <Route path="faq" exact={true} element={<Faq />} />
            <Route path="faq/add" exact={true} element={<AddNew />} />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toast />
    </div>
  );
}

export default App;
