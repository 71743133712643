import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Creator = ({ show, onClose, singleUser }) => {
  const [loading, setLoading] = useState(false);
  const saveClick = async () => {
    setLoading(true);
    let obj = {
    is_enable_uc_toggle_btn : singleUser.is_enable_uc_toggle_btn === 0 ? 1 : 0,
    };
    obj.user_id = singleUser._id;
    const res = await postData("/toggle-user-creator-enable", {}, obj);
    if (res.status === 1) {

      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const cancelClick = () => {
    onClose(false);
  }

  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading={
        singleUser.is_enable_uc_toggle_btn === 1
          ? "Disable creator"
          : "Enable creator"
      }
    >
      <div className="form-group">
        <p>
          Are you sure you want to{" "}
          {singleUser.is_enable_uc_toggle_btn === 1 ? "disable" : "enable"} creator ?
        </p>
      </div>
      <div className="text-end">
          <Button
            type="button"
            className="btn-default text-blacksix me-3"
            onClick={cancelClick}
          >
            No
          </Button>
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default Creator;
