export const settingField = [
  {
    label: "Name",
    name: "name",
    type: "text",
    placeholder: "Enter name",
    errorMessage: "Name is required",
    disabled: false,
  },
  {
    label: "Max video duration for video (in seconds)",
    name: "mvd_for_video",
    type: "number",
    placeholder: "Enter duration",
    errorMessage: "Duratin is required",
    disabled: false,
  },
  {
    label: "Max video duration in playlist (in seconds)",
    name: "mvd_for_playlist",
    type: "number",
    placeholder: "Enter duration",
    errorMessage: "Duratin is required",
    disabled: false,
  },
  {
    label: "Max video duration in Categories (in seconds)",
    name: "mvd_for_categories",
    type: "number",
    placeholder: "Enter duration",
    errorMessage: "Duratin is required",
    disabled: false,
  },
  {
    label: "Max video duration of intro video (in seconds)",
    name: "mvd_for_ivideo",
    type: "number",
    placeholder: "Enter duration",
    errorMessage: "Duratin is required",
    disabled: false,
  },
  {
    label: "Email",
    name: "email",
    type: "email",
    placeholder: "Enter email",
    errorMessage: "Email is not valid",
    disabled: true,
  },
];
