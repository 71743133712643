import { useEffect, useState } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";
import Invite from "./Invite";
import Status from "./Status";
import View from "./View";
import Manage from "./Manage";
import { Link } from "react-router-dom";
import Creator from "./Creator";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
const Users = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [inviteShowModal, setInviteShowModal] = useState(false);

  const [singleUser, setSingleUser] = useState(null);
  const [statusShowModal, setStatusShowModal] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);
  const [creatorShowModal, setCreatorShowModal] = useState(false);

  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          {/* {row.onboard_process === "Completed" && */}
          <button className="btn btn-link me-3" onClick={() => EditClick(row)}>
            <i className="fa fa-pencil"></i>
          </button>
          {/* } */}
          <button
            className="btn btn-link me-3"
            onClick={() => statusClick(row)}
          >
            {row.is_active === 1 ? (
              <i className="fa fa-toggle-on"></i>
            ) : (
              <i className="fa fa-toggle-off"></i>
            )}
          </button>
        </div>
      </>
    );
  };
  const creatoractionButton = (cell, row) => {
    if (row.creator_status === "sent") {
      return (
        <>
          <div className="action-buttons">
            <span className="me-3"><span className="no-icon">-</span></span>

            <button
              disabled={row.creator_status === "pending"}
              className="btn btn-link"
              onClick={() => creatorToggleClick(row)}
            >
              {row.is_enable_uc_toggle_btn === 1 ? (
                <i className="fa fa-toggle-on"></i>
              ) : (
                <i className="fa fa-toggle-off"></i>
              )}
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="action-buttons">
            <button
              className="btn btn-link me-3"
              disabled={row.onboard_process === "Inprogress"}
              onClick={() => sendInviteClick(row)}
              loading={loading}
            >
              <i className="fa fa-envelope"></i>
            </button>

            <button
              disabled={row.creator_status === "pending"}
              className="btn btn-link"
              onClick={() => creatorToggleClick(row)}
            >
              {row.is_enable_uc_toggle_btn === 1 ? (
                <i className="fa fa-toggle-on"></i>
              ) : (
                <i className="fa fa-toggle-off"></i>
              )}
            </button>
          </div>
        </>
      );
    }
  };
  const viewProfile = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button
            className="btn btn-link fw-600"
            onClick={() => viewClick(row)}
          >
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };
  const capitalizeFirstLetter = str => {
    return (
      str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    );
  };
  const PhoneNumber = (cell, row) => {
    return <>{row.profile.phone_no}</>;
  };
  const columns = [
    {
      dataField: "user_name",
      text: "Username",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "phone_no",
      text: "Phone number",
      headerStyle: { width: "15%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
      formatter: PhoneNumber,
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "onboard_process",
      text: "Onboard status",
    },
    {
      dataField: "profile",
      text: "Profile",
      formatter: viewProfile,
    },
    {
      dataField: "creator_status",
      text: "Creator status",
      formatter: capitalizeFirstLetter ,
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "action",
      text: "Creator action",
      formatter: creatoractionButton,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];
  const getUsetList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      role: "all",
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/admin-user-list", {}, obj);
      if (getData && getData.status === 1) {
        setUserList(getData.data.users);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getUsetList(page, sizePerPage, search);
  };
  useEffect(() => {
    getUsetList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const inviteClick = () => {
    setInviteShowModal(true);
  };
  const onInviteClose = (status) => {
    if (status) getUsetList(page, sizePerPage, "");
    setInviteShowModal(false);
  };
  const EditClick = (row) => {
    row.profile.dob =
      row.profile.dob === ""
        ? null
        : !row.profile.dob
        ? row.profile.dob
        : new Date(row.profile.dob);
    console.log(row);
    setSingleUser(row);
    setAddShowModal(true);
  };
  const sendInviteClick = async (singleUser) => {
    setLoading(true);
    let obj = {
      user_id : singleUser._id,
    };
    const res = await postData("/user-creator-email-trigger", {}, obj);
    if (res.status === 1) {      
      //setLoading(false);
      getUsetList(page, sizePerPage, "");
      toast.success(res.message, { theme: "colored" });
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const statusClick = (row) => {
    setSingleUser(row);
    setStatusShowModal(true);
  };

  const creatorToggleClick = (row) => {
    setSingleUser(row);
    setCreatorShowModal(true);
  };
  const onAddClose = (status) => {
    if (status) getUsetList(page, sizePerPage);
    setSingleUser(null);
    setStatusShowModal(false);
    setAddShowModal(false);
    setCreatorShowModal(false);
  };
  const viewClick = (row) => {
    setSingleUser(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    if (status) getUsetList(page, sizePerPage);
    setSingleUser(null);
    setViewShowModal(false);
  };
  return (
    <div>
      <h4 className="title">Users</h4>
      <ServerSideTable
        columns={columns}
        data={userList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      >
        <div className="action-group text-end">
          <Link
            to="/users/inviteStatus"
            className="btn f-14 fw-600 btn-sm text-white btn-primary me-4"
          >
            Invite status
          </Link>
          <button
            className="btn f-14 fw-600 btn-sm text-white btn-primary"
            onClick={inviteClick}
          >
            Invite
          </button>
        </div>
      </ServerSideTable>
      {inviteShowModal && (
        <Invite show={inviteShowModal} onClose={onInviteClose} />
      )}
      {statusShowModal && (
        <Status
          show={statusShowModal}
          onClose={onAddClose}
          singleUser={singleUser}
        />
      )}
      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          singleUser={singleUser}
        />
      )}
      {addShowModal && (
        <Manage
          show={addShowModal}
          onClose={onAddClose}
          singleUser={singleUser}
        />
      )}
      {creatorShowModal && (
        <Creator
          show={creatorShowModal}
          onClose={onAddClose}
          singleUser={singleUser}
        />
      )}
    </div>
  );
};

export default Users;
