import { useState, useEffect } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import Create from "./Create";
import View from './View'
import { postData } from "../../api";
import Resend from "./Resend";

const Notifications = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [resendModal,setResendModal] =useState(false)
  const [singleNotification, setSingleNotification] = useState(null)
  const [notifyList, setNotifyList] = useState([]);
  const [loading, setLoading] = useState(false);

  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button className="btn btn-link me-3" onClick={() => viewClick(row)}>
            <i className="fa fa-eye"></i>
          </button>
          {/* {row.status === 0 &&
          <button className="btn btn-link" onClick={() => resentClick(row)}>
            Resend
          </button>} */}
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "",
      text: "S.No",  
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return rowIndex + 1;
      },
      sort: true,
    },
    {
      dataField: "targeted_user",
      text: "Targeted user",
    },
    {
      dataField: "title",
      text: "Notification title",
    },
    {
      dataField: "create_at",
      text: "Notification create",
    },
    {
      dataField: "count_message",
      // text: "Sent on",
      text: "Success",
    },
    {
      dataField: "status_message",
      text: "Status",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];


  const getDataList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/get-all-custom-notification", {}, obj);
      if (getData && getData.status === 1) {
        setNotifyList(getData.data.notificationList);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };

  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getDataList(page, sizePerPage, search);
  };

  useEffect(() => {
    getDataList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createModalOpen = (row) => {
    setSingleNotification(row);
    setCreateModal(true);
  };

  const oncreateClose = (status) => {
    setSingleNotification(null);
    setCreateModal(false);
    getDataList(page, sizePerPage, "")
  }

  const resentClick = (row) => {
    setSingleNotification(row);
    setResendModal(true);
    setViewShowModal(false);
  };
  const onResendClose = (status) => {
    setSingleNotification(null);
    setResendModal(false);
  }

  const viewClick = (row) => {
    setSingleNotification(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    setSingleNotification(null);
    setViewShowModal(false);
  }
  return (
    <div>
      <h4 className="title">Notifications</h4>
      <ServerSideTable
        columns={columns}
        data={notifyList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      >
        <div className="action-group text-end">
          <button
            className="btn f-14 fw-600 btn-sm text-white btn-primary"
            onClick={createModalOpen}
          >
            Create notification
          </button>
        </div>
      </ServerSideTable>
      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          singleNotification={singleNotification}
          failViewClick={resentClick}
        />
      )}
      {createModal && (
        <Create
        show={createModal}
        onClose={oncreateClose}
        />
      )}
      {resendModal && (
        <Resend
        show={resendModal}
        onClose={onResendClose}
        singleNotification={singleNotification}
        />
      )}
    </div>
  );
}
export default Notifications