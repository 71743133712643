import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {postData } from "../../api";
import Loader from "../../components/Loader";
import View from "./View";
import ClientSideTable from "../../components/ClientSideTable";
import "./style.scss";
const DashBoard = () => {
  const [initLoading, setInitLoading] = useState(false);
  const [topCreatorsList,setTopCreatorsList]=useState([]);
  const [dashboardData,setdashboardData]=useState({});
  const [singleTopCreatorProfile, setSingleTopCreatorProfile] = useState(null);
  const [viewShowModal, setViewShowModal] = useState(false);
  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button className="btn btn-link" onClick={() => viewClick(row)}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };
  const columns = [
    {
      dataField: "sl.no",
      text: "S.no",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return  rowIndex+1;
      },
    },
    {
      dataField: "name",
      text: "Creator name",
    },
    {
      dataField: "interest",
      text: "Interest",
      formatter: (row) => {
        return row?.length > 0 ? row?.join(', ').substring(0,15) + '...': '-';
      },
    },
    {
      dataField:'videos',
      text:"Video"
    },
    {
      dataField:'followers',
      text:"Followers"
    },
    {
      dataField:'subscribers',
      text:"Paid subscribers"
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];
  const convertNumnber=(value)=>
  {
    if(value>=1000000)
    {
        value=(value/1000000)+"M"
    }
    else if(value>=1000)
    {
        value=(value/1000)+"K";
    }
    return value;
  }
  useEffect(() => {
    getDashboardData();
  }, []);
 const getDashboardData = async()=> {
  setInitLoading(true);
    try {
      const getData = await postData("/admin-dashboard", {}, '');
      if (getData && getData.status === 1) {
        setTopCreatorsList(getData.data.top_creators);
        setdashboardData(getData.data.report);
        setInitLoading(false);
      }
    } catch (err) {}
  }
  const viewClick = (row) => {
    setSingleTopCreatorProfile(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    setSingleTopCreatorProfile(null);
    setViewShowModal(false);
  };
  return (
    <div>
      <h4 className="title mb-3">Dashboard</h4>
      <div className="dashboard_page">
        <div className="row">
          {Object.keys(dashboardData).length !==0 ? 
          <>
             <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Users</h4>
              <h2>{convertNumnber(dashboardData?.users?.count)}</h2>
              <p>{dashboardData?.users?.type ==='incr' ? (dashboardData?.users?.percentage+'% Increase') : dashboardData?.users?.percentage+'% Decrease'} in new user</p>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Creators</h4>
              <h2>{convertNumnber(dashboardData.creators?.count)}</h2>
              <p>{dashboardData.creators?.type ==='incr' ? (dashboardData.creators?.percentage+'% Increase') :dashboardData.creators?.percentage+'% Decrease'} in Content Creators</p>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Active users</h4>
              <h2 className="mb-3">{convertNumnber(dashboardData.active_users)}</h2>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Subscriptions</h4>
              <h2 >{convertNumnber(dashboardData.paid_subscribers.count)}</h2>
              <p>{dashboardData.paid_subscribers?.type ==='incr' ? (dashboardData.paid_subscribers?.percentage+'% Increase') :dashboardData.paid_subscribers?.percentage+'% Decrease'} from last month</p>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Transactions</h4>
              <h2>&#36;{dashboardData.transactions?.count}</h2>
              <p>{dashboardData.transactions?.type ==='incr' ? (dashboardData.transactions?.percentage+'% Increase') :dashboardData.transactions?.percentage+'% Decrease'} from last month</p>
            </div>
          </div>
          <div className="col-md-3 mb-3">
            <div className="dashboard_card">
              <h4>Videos</h4>
              <h2>{convertNumnber(dashboardData.videos?.count)}</h2>
              <p>{dashboardData.videos?.type ==='incr' ? (dashboardData.videos?.percentage+'% Increase') :dashboardData.videos?.percentage+'% Decrease'} from last month</p>
            </div>
          </div>
          </>  
       :null}
        
        </div>
        <div className="row mt-4">
          <div className="col-md-6">
            <h4 className="title">Top creators </h4>
          </div>
          <div className="col-md-6">
            <div className="action-group text-end">
              
              <Link
              to="/topcreators"
              className="btn f-14 fw-600 btn-sm text-white btn-primary me-4"
              >
                    View all
              </Link>
            </div>
          </div>
        </div>
       
        <ClientSideTable columns={columns} data={topCreatorsList} />
        {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          TopCreatorProfile={singleTopCreatorProfile}
        />
      )}
      </div>
      {initLoading && <Loader />}
    </div>
  );
};

export default DashBoard;
