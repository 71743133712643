import React, { useState, useEffect } from "react";
import FormModal from "../../components/Modal";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";

const Resend = ({ show, onClose, singleNotification }) => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [loading, setLoading] = useState(false);
  const [failList, setFailList] = useState([]);

  const columns = [
    {
      dataField: "name",
      text: "User name",
      headerStyle: { width: "20%", textAlign: "left" },
      style: { textAlign: "left", whiteSpace: "normal", paddingLeft: "25px" },
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "20%", textAlign: "left" },
      style: { textAlign: "left", whiteSpace: "normal", paddingLeft: "25px" },
    },
    {
      dataField: "reason",
      text: "Reason",
    },
  ];

  const getDataList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      search: {
        value: search,
      },
      id: singleNotification?._id,
    };
    try {
      const getData = await postData("/resent-notification-list", {}, obj);
      if (getData && getData.status === 1) {
        setFailList(getData.data.failure_user_list);
        setTotalSize(getData.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };

  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getDataList(page, sizePerPage, search);
  };

  useEffect(() => {
    getDataList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="View failed notification"
      size="xl"
      className="Resent-model"
    >
      <div className="row form-group">
        <ServerSideTable
          columns={columns}
          data={failList}
          page={page}
          sizePerPage={sizePerPage}
          totalSize={totalSize}
          onFilter={onFilter}
          loading={loading}
        ></ServerSideTable>
      </div>
    </FormModal>
  );
};

export default Resend;
