import React, { useState, useEffect } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";
import Button from "../../components/Form/Button";
import ConfirmPopup from "./ConfirmPopup";
import PageBreadcrumb from "../../components/Breadcrumb";
import { useLocation } from "react-router-dom";
import "./style.scss";

const InviteStatus = (props) => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [inviteStatusList, setInviteStatusList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [singleInvite, setSingleInvite] = useState(null);
  const [resendShowModal, setResendShowModal] = useState(false);

  const getInviteList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      role: getLocationData(location) === "users" ? "user" : "creator",
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/get-user-invite", {}, obj);
      if (getData && getData.status === 1) {
        setInviteStatusList(getData.data.invites);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };
  const dateFormat = (data) => {
    let date = new Date(data);
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return "" + (d <= 9 ? "0" + d : d) + "-" + (m <= 9 ? "0" + m : m) + "-" + y;
  };
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getInviteList(page, sizePerPage, search);
  };
  const getLocationData = (data) => {
    let links = data.pathname.split("/");
    return links[1];
  };
  useEffect(() => {
    getInviteList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddClose = (status) => {
    // if (status) getFaqLists();
    setSingleInvite(null);
    setResendShowModal(false);
  };
  const resendClick = (row) => {
    setResendShowModal(true);
    setSingleInvite(row);
  };
  const createdAt = (cell, row) => {
    return <div>{dateFormat(cell)}</div>;
  };
  const statusFormat = (cell, row) => {
    return <div>{cell === 'invite' ? "Invited" : cell}</div>;
  };
  
  const actionButton = (cell, row) => {
    if (row.status === "invite") {
      return (
        <>
          <div className="action-status-buttons">
            <Button
              type="button"
              className="btn-primary text-white"
              onClick={() => resendClick(row)}
            >
              Resend
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="action-status-buttons">
            -
            {/* <Button type="button" className="btn-default text-blacksix" disabled>
              Sent
            </Button> */}
          </div>
        </>
      );
    }
  };

  const columns = [
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "25%", textAlign: "left" },
      style:{ textAlign: "left",paddingLeft:"25px" }
    },
    {
      dataField: "created_at",
      text: "Sent on",
      formatter: createdAt,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: statusFormat
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];
  const breadcrumb_lists = [
    {
      heading:
        getLocationData(location) === "users" ? "User" : "Content creators",
      link:
        getLocationData(location) === "users" ? "/users" : "/contentCreators",
    },
    {
      heading: "Invite status",
      link: "",
    },
  ];

  return (
    <div>
      <PageBreadcrumb breadcrumb_lists={breadcrumb_lists}></PageBreadcrumb>
      <h4 className="title">Invite status</h4>
      {/* <ClientSideTable columns={columns} data={data} /> */}
      <ServerSideTable
        columns={columns}
        data={inviteStatusList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      ></ServerSideTable>
      {resendShowModal && (
        <ConfirmPopup
          show={resendShowModal}
          onClose={onAddClose}
          role={getLocationData(location) === "users" ? "user" : "creator"}
          singleRow={singleInvite}
          setSingleRow={setSingleInvite}
        />
      )}
    </div>
  );
};

export default InviteStatus;
