// import Button from 'react-bootstrap/Button';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Row from 'react-bootstrap/Row';

import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import { postData } from "../../api";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

    const AddNew = ({ show }) => {
      let navigate = useNavigate();

    const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [AddNewObject, setAddNewObject] = useState({
    question: "",
    answer:"",
    role: window.location.hash.replace('#',''),
  });
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddNewObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      addNewClick();
    }
  };
  const addNewClick = async () => {
    setLoading(true);

    const res = await postData("/admin-faq-update-or-create", {}, AddNewObject);
    if (res.status === 1) {
      setLoading(false);
      navigate('/faq');
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="faq_addNew_page">
        <h4 className='title mb-3'>Add New</h4>
      <Form noValidate validated={validated} onSubmit={handleSubmit} id="faq_addNew_form">
        <Input
          label="Question"
          type="text"
          name="question"
          value={AddNewObject.question}
          size="lg"
          placeholder="Enter question"
          errorMessage="Question is not valid"
          onChange={handleInput}
        ></Input>
        <TextArea
          label="Answer"
          type="text"
          name="answer"
          value={AddNewObject.answer}
          size="lg"
          placeholder="Enter answer"
          errorMessage="Answer is not valid"
          onChange={handleInput}
        ></TextArea>
        <div className="text-end">
        <Button
            type="button"
            className="btn-default text-blacksix me-3"
            disabled={loading}
          >
            <Link to="/faq" className="text-white text-decoration-none">
                Cancel
            </Link>
            
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            Submit
          </Button>
        </div>
      </Form>
    {/* <Form>
      <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
        <Form.Label column sm={2}>
          Question
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="text" placeholder="Enter Question" />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="formHorizontalPassword">
        <Form.Label column sm={2}>
          Answer
        </Form.Label>
        <Col sm={8}>
          <Form.Control as="textarea" type="text" placeholder="Enter Answer" />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3">
        <Col sm={{ span: 8, offset: 2 }}>
          <Button type="button" className='text-white btn-sm btn-default me-2'>Cancel</Button>
          <Button type="submit" className='text-white btn-sm'>Sign in</Button>
        </Col>
      </Form.Group>
    </Form> */}
    </div>
  );
}

export default AddNew;