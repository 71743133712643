import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
import CKEditor from "react-ckeditor-component";
let config = {
  toolbarGroups: [
    {
      name: "editing",
      groups: ["find", "selection", "spellchecker", "editing"],
    },
    { name: "forms", groups: ["forms"] },
    { name: "basicstyles", groups: ["basicstyles"] },
    {
      name: "paragraph",
      groups: ["list"]
    },
    { name: "links", groups: ["links"] },
  ],
  removeButtons:
    "Save,NewPage,Preview,Print,Templates,Cut,Copy,Paste,PasteText,PasteFromWord,Find,SelectAll,Scayt,Replace,Form,Checkbox,Textarea,Select,Button,ImageButton,HiddenField,CreateDiv,BidiLtr,BidiRtl,Language,Flash,Smiley,SpecialChar,PageBreak,Iframe,Anchor,ShowBlocks,About,CopyFormatting,Undo,Redo,Subscript,Superscript",
  fontSize_sizes: "16/16px;24/24px;48/48px;",
  font_names:
    "Arial/Arial, Helvetica, sans-serif;" +
    "Times New Roman/Times New Roman, Times, serif;" +
    "Verdana",
  allowedContent: true,
  // disableNativeSpellChecker: false
  // skin: "moono",
  // plugins:
  //   "dialogui,dialog,about,a11yhelp,dialogadvtab,basicstyles,bidi,blockquote,notification,button,toolbar,clipboard,panelbutton,panel,floatpanel,colorbutton,colordialog,templates,menu,contextmenu,copyformatting,div,resize,elementspath,enterkey,entities,popup,filetools,filebrowser,find,fakeobjects,flash,floatingspace,listblock,richcombo,font,forms,format,horizontalrule,htmlwriter,iframe,wysiwygarea,image,indent,indentblock,indentlist,smiley,justify,menubutton,language,link,list,liststyle,magicline,maximize,newpage,pagebreak,pastetext,pastefromword,preview,print,removeformat,save,selectall,showblocks,showborders,sourcearea,specialchar,scayt,stylescombo,tab,table,tabletools,tableselection,undo,lineutils,widgetselection,widget,notificationaggregator,uploadwidget,uploadimage,wsc",
};
const Manage = ({ show, onClose, singleFaq, whichTab }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
    const [AddNewObject, setAddNewObject] = useState({
    question: "",
    answer:"",
    role: whichTab,
  });
  const cancelClick = () => {
    onClose(false);
  }

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setAddNewObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onChange = (e) => {
    const value = e.editor.getData();
    setAddNewObject((prev) => ({
      ...prev,
      answer: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveClick();
    }
  };
  const saveClick = async () => {
    setLoading(true);
    if (singleFaq) {
        AddNewObject._id = singleFaq._id;
    }
    const res = await postData("/admin-faq-update-or-create", {}, AddNewObject);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (singleFaq) {
        setAddNewObject((prev) => ({
        ...prev,
        question: singleFaq.question,
        answer: singleFaq.answer,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading={singleFaq ? "Update FAQ" : "Add FAQ"}
      size="lg"
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Input
          label="Question"
          type="text"
          name="question"
          value={AddNewObject.question}
          size="lg"
          placeholder="Enter question"
          errorMessage="Question is not valid"
          onChange={handleInput}
          maxLength={255}
        ></Input>
          <div className="mb-3">
            <label className="fw-600 f-14 text-black form-label" >Answer</label>
          <CKEditor
                activeClass="p10"
                config={config}
                content={AddNewObject.answer}
                events={{
                  change: onChange,
                }}
              />
              </div>
        {/* <TextArea
          label="Answer"
          type="text"
          name="answer"
          value={AddNewObject.answer}
          size="lg"
          placeholder="Enter answer"
          errorMessage="Answer is not valid"
          onChange={handleInput}
        ></TextArea> */}
        <div className="text-end">
        <Button
            type="button"
            className="btn-default text-blacksix me-3"
            onClick={cancelClick}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            {singleFaq ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default Manage;
