import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Manage = ({ show, onClose, singleInterest }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [interestObject, setInterestObject] = useState({
    name: "",
  });

  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setInterestObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveClick();
    }
  };
  const saveClick = async () => {
    setLoading(true);
    if (singleInterest) {
      interestObject.id = singleInterest._id;
    }
    const res = await postData("/add-update-interest", {}, interestObject);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  useEffect(() => {
    if (singleInterest) {
      setInterestObject((prev) => ({
        ...prev,
        name: singleInterest.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const cancelClick = () => {
    onClose(false);
  }
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading={singleInterest ? "Update interest" : "Add interest"}
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Input
          label="Interest name"
          type="text"
          name="name"
          value={interestObject.name}
          size="lg"
          placeholder="Enter interest name"
          errorMessage="Interest is required"
          onChange={handleInput}
        ></Input>
        <div className="text-end">
            <Button
                type="button"
                className="btn-default text-blacksix me-2"
                onClick={cancelClick}
              >
                Cancel
              </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            {singleInterest ? "Update" : "Add"}
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default Manage;
