import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Status = ({ show, onClose, singleInterest }) => {
  const [loading, setLoading] = useState(false);
  const saveClick = async () => {
    setLoading(true);
    let obj = {
      active_status: singleInterest.active_status === 0 ? 1 : 0,
    };
    obj.id = singleInterest._id;
    const res = await postData("/active-deactive-interest", {}, obj);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const cancelClick = () => {
    onClose(false);
  };

  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading={
        singleInterest.active_status === 1
          ? "Disable interest"
          : "Enable interest"
      }
    >
      <div className="form-group">
        <p>
          Are you sure you want to{" "}
          {singleInterest.active_status === 1 ? "disable" : "enable"} interest ?
        </p>
      </div>
      <div className="text-end">
        <Button
          type="button"
          className="btn-default text-blacksix me-3"
          onClick={cancelClick}
        >
          No
        </Button>
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default Status;
