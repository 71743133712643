import React, { useState, useEffect } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";
import Invite from "./Invite";
import Status from "./Status";
import View from "./View";
import Manage from "./Manage";
import { Link } from "react-router-dom";

const ContentCreators = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [inviteShowModal, setInviteShowModal] = useState(false);
  const [singleCreator, setSingleCreator] = useState(null);
  const [statusShowModal, setStatusShowModal] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);

  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
        {/* {row.onboard_process === "Completed" && */}
          <button className="btn btn-link me-3" onClick={() => EditClick(row)}>
            <i className="fa fa-pencil"></i>
          </button>
          {/* } */}

          <button className="btn btn-link " onClick={() => statusClick(row)}>
            {row.is_active === 1 ? (
              <i className="fa fa-toggle-on"></i>
            ) : (
              <i className="fa fa-toggle-off"></i>
            )}
          </button>
        </div>
      </>
    );
  };
  const viewProfile = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button className="btn btn-link" onClick={() => viewClick(row)}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "25%", textAlign: "left" },
      style:{ textAlign: "left",paddingLeft:"25px" }
    },
    {
      dataField: "interest_name",
      text: "Interest",
      headerStyle: { width: "25%", textAlign: "left" },
      style:{ textAlign: "left", whiteSpace: "normal", paddingLeft:"25px"  }
    },
    {
      dataField: "email",
      text: "Email",
      headerStyle: { width: "25%", textAlign: "left" },
      style:{ textAlign: "left", whiteSpace: "normal", paddingLeft:"25px"  }
    },
    {
      dataField: "onboard_process",
      text: "Onboard status"
    },
    {
      dataField: "profile",
      text: "Profile",
      formatter: viewProfile,
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];

  const getUsetList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      role: "creator",
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/admin-user-list", {}, obj);
      if (getData && getData.status === 1) {
        setUserList(getData.data.users);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getUsetList(page, sizePerPage, search);
  };
  useEffect(() => {
    getUsetList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inviteClick = () => {
    setInviteShowModal(true);
  };
  const onInviteClose = (status) => {
    if (status) getUsetList(page, sizePerPage, "");
    setInviteShowModal(false);
  };
  const EditClick = (row) => {
    setSingleCreator(row);
    setAddShowModal(true);
  };
  const statusClick = (row) => {
    setSingleCreator(row);
    setStatusShowModal(true);
  };
  const onAddClose = (status) => {
    if (status) getUsetList(page, sizePerPage);
    setSingleCreator(null);
    setStatusShowModal(false);
    setAddShowModal(false);
  };
  const viewClick = (row) => {
    setSingleCreator(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    if (status) getUsetList(page, sizePerPage);
    setSingleCreator(null);
    setViewShowModal(false);
  };

  return (
    <div>
      <h4 className="title">Content creators</h4>
      {/* <ClientSideTable columns={columns} data={data} /> */}
      <ServerSideTable
        columns={columns}
        data={userList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      >
        <div className="action-group text-end">
          <Link
            to="/contentCreators/inviteStatus"
            className="btn f-14 fw-600 btn-sm text-white btn-primary me-4"
          >
            Invite status
          </Link>
          <button
            className="btn f-14 fw-600 btn-sm text-white btn-primary"
            onClick={inviteClick}
          >
            Invite
          </button>
        </div>
      </ServerSideTable>

      {inviteShowModal && (
        <Invite show={inviteShowModal} onClose={onInviteClose} />
      )}
      {statusShowModal && (
        <Status
          show={statusShowModal}
          onClose={onAddClose}
          singleCreator={singleCreator}
        />
      )}
      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          singleCreator={singleCreator}
        />
      )}
      {addShowModal && (
        <Manage
          show={addShowModal}
          onClose={onAddClose}
          singleCreator={singleCreator}
        />
      )}
    </div>
  );
};

export default ContentCreators;
