import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./style.scss"

const PageBreadcrumb = ({ breadcrumb_lists }) => {
  return (
    <Breadcrumb className='custom_breadcrumb'>
    {breadcrumb_lists.map(function(val, key){
        if(val.link === ""){
            return <Breadcrumb.Item active>{val.heading}</Breadcrumb.Item>
        } else {
            return <Breadcrumb.Item href={val.link}>{val.heading}</Breadcrumb.Item>
        }
    })}
    </Breadcrumb>
  );
}

export default PageBreadcrumb;