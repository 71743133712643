import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Loader from "../../components/Loader";
import Input from "../../components/Form/Input";
import Button from "../../components/Form/Button";
import { getData, postData } from "../../api";
import { settingField } from "./options";
import { toast } from "react-toastify";

const Settings = () => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const data = {
    privacy_policy: "",
    term_and_condition: "",
  };
  const [settingObject, setSettingObject] = useState({
    email: "",
    name: "",
    mvd_for_categories: 0,
    mvd_for_ivideo: 0,
    mvd_for_playlist: 0,
    mvd_for_video: 0,
    user: data,
    creater: data,
  });
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setSettingObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveSettingClick();
    }
  };
  const getUserSetting = async () => {
    setInitLoading(true);
    const res = await getData("/admin-general-setting-view", {});
    if (res.status === 1) {
      setSettingObject((...prev) => ({
        ...prev,
        email: res.data?.user?.email,
        name: res.data?.user?.name,
        mvd_for_categories: res.data?.slug?.mvd_for_categories,
        mvd_for_ivideo: res.data?.slug?.mvd_for_ivideo,
        mvd_for_playlist: res.data?.slug?.mvd_for_playlist,
        mvd_for_video: res.data?.slug?.mvd_for_video,
        user: res.data?.slug?.user,
        creator: res.data?.slug?.creator,
      }));
      setInitLoading(false);
    } else if (res.statusCode === 422) {
      setInitLoading(false);
    } else {
      setInitLoading(false);
    }
  };
  const saveSettingClick = async () => {
    setLoading(true);
    let obj = {
      user: {
        name: settingObject.name,
      },
      slug: {
        mvd_for_video: +settingObject.mvd_for_video,
        mvd_for_playlist: +settingObject.mvd_for_playlist,
        mvd_for_categories: +settingObject.mvd_for_categories,
        mvd_for_ivideo: +settingObject.mvd_for_ivideo,
        user: settingObject.user,
        creator: settingObject.creator,
      },
    };
    const res = await postData("/admin-general-setting-save", {}, obj);
    if (res.status === 1) {
      toast.success(res.message, { theme: "colored" });
      setLoading(false);
    } else if (res.statusCode === 422) {
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res.message, { theme: "colored" });
    }
  };
  useEffect(() => {
    getUserSetting();
  }, []);
  return (
    <div>
      {/* <Heading title="General settings" /> */}
      <h4 className="title">General settings</h4>
      <div className="card">
        <div className="card-body">
          {!initLoading && (
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  {settingField.map((item, index) => (
                    <React.Fragment key={index}>
                      <Input
                        {...item}
                        value={settingObject[item.name]}
                        size="lg"
                        onChange={handleInput}
                      ></Input>
                    </React.Fragment>
                  ))}

                  <div className="text-end">
                    <Button
                      type="submit"
                      disabled={loading}
                      loading={loading}
                      className="btn-primary text-white"
                    >
                      Update
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
          {initLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};
export default Settings;
