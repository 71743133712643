import React from "react";
import "./style.scss";
import FormModal from "../../components/Modal";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
const Status = ({ show, onClose, singleNotification, failViewClick }) => {
  //   const [resendModal,setResendModal] =useState(false)
  //   // const [singleNotification, setSingleNotification] = useState(null)

  // console.log("singleNotification",singleNotification)
  //   const resentClick = (singleNotification) => {
  //     // console.log("first",row)
  //     // setSingleNotification(singleNotification);
  //     setResendModal(true);
  //   };
  //   const onResendClose = (status) => {
  //     // setSingleNotification(null);
  //     setResendModal(false);
  //   }

  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="View notification"
      size="md"
    >
      <div className="row form-group">
        <Input
          label="Notification title"
          value={singleNotification?.title}
          size="lg"
          disabled={true}
        />
        <TextArea
          label="Message"
          size="lg"
          value={singleNotification?.message}
          disabled={true}
        />
        <div className="row form-group">
          <div className="col-md-4">
            <p className="f-14 fw-600 mb-0">Send to</p>
          </div>
          <div className="col-md-1">
            <p className="f-14 fw-600 mb-0">:</p>
          </div>
          <div className="col-md-7">
            <p className="f-14 fw-400 mb-0">
              {singleNotification?.targeted_user}
            </p>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-4">
            <p className="f-14 fw-600 mb-0">Total users</p>
          </div>
          <div className="col-md-1">
            <p className="f-14 fw-600 mb-0">:</p>
          </div>
          <div className="col-md-7">
            <p className="f-14 fw-400 mb-0">
              {singleNotification?.total_count}
            </p>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-4">
            <p className="f-14 fw-600 mb-0">success count</p>
          </div>
          <div className="col-md-1">
            <p className="f-14 fw-600 mb-0">:</p>
          </div>
          <div className="col-md-7">
            <p className="f-14 fw-400 mb-0">
              {singleNotification?.success_count}
            </p>
          </div>
        </div>

        <div className="row form-group">
          <div className="col-md-4">
            <p className="f-14 fw-600 mb-0">Failure count</p>
          </div>
          <div className="col-md-1">
            <p className="f-14 fw-600 mb-0">:</p>
          </div>
          <div className="col-md-7">
            <p className="f-14 fw-400 mb-0">
              {singleNotification?.failure_count}
            </p>
            {singleNotification?.failure_count > 0 && (
              <button
                className="btn btn-link position-absolute fail-view"
                onClick={() => failViewClick(singleNotification)}
              >
                View
              </button>
            )}
          </div>
        </div>
      </div>
      {/* {resendModal && (
        <Resend
        show={resendModal}
        onClose={onResendClose}
        singleNotification={singleNotification}
        />
      )} */}
    </FormModal>
  );
};

export default Status;
