import React from "react";
import FormModal from "../../components/Modal";
const Status = ({ show, onClose, singleUser }) => {
  
  const dateFormat = (data) => {
    let date = new Date(data);
    let d = date.getDate();
    let m = date.getMonth() + 1; //Month from 0 to 11
    let y = date.getFullYear();
    return ""+(m <= 9 ? "0" + m : m) + "/"  + (d <= 9 ? "0" + d : d) + "/" + y;
  };
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="User profile information"
      size="lg"
    >
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Name</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleUser?.name}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Username</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleUser?.user_name}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Email </p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleUser?.email}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Phone number</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">
            {singleUser?.profile?.phone_no
              ? singleUser?.profile?.phone_no
              : "-"}
          </p>
        </div>
      </div>

      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Gender</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">
            {singleUser?.profile?.gender ? singleUser?.profile?.gender : "-"}
          </p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Birthday</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">
            {singleUser?.profile?.dob
              ? dateFormat(singleUser?.profile?.dob)
              : singleUser?.profile?.dob === "" ? "-" : "-"}
          </p>
        </div>
      </div>
    </FormModal>
  );
};

export default Status;
