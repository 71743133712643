import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { getData } from "../../api";
import { toast } from "react-toastify";
const Status = ({ show, onClose, singleFaq }) => {
  const [loading, setLoading] = useState(false);
  const saveClick = async () => {
    setLoading(true);
    const res = await getData("/admin-faq-delete/" +singleFaq._id, {});
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const cancelClick = () => {
    onClose(false);
  }

  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading={
        "Delete FAQ"
      }
    >
      <div className="form-group">
        <p>
          Are you sure you want to delete ?
        </p>
      </div>
      <div className="text-end">
      <Button
            type="button"
            className="btn-default text-blacksix me-3"
            onClick={cancelClick}
          >
            No
          </Button>
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default Status;
