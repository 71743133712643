import React from "react";
import { useState } from "react";
import FormModal from "../../components/Modal";
const TopCreatorProfile = ({ show, onClose, TopCreatorProfile }) => {
    console.log(TopCreatorProfile)
  const [profileDetail] = useState([
    {
      text: "Name",
      dataField: "name",
    },
    {
      text: "Email ID",
      dataField: "email",
    },
    {
      text: "Interest",
      dataField: "interest",
    },
    {
      text: "No of followers",
      dataField: "followers",
    },
    {
      text: "No of subscribers",
      dataField: "subscribers",
    }
  ]);
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="Creator profile info"
      size="lg"
    >
      {profileDetail &&
        profileDetail.map((item, index) => (
          <div key={index} className="row form-group">
            <div className="col-md-4">
              <p className="f-14 fw-600 mb-0">{item.text}</p>
            </div>
            <div className="col-md-1">
              <p className="f-14 fw-600 mb-0">:</p>
            </div>
            <div className="col-md-7">
              <p className="f-14 fw-400 mb-0">
                {item.dataField === "interest" ?  TopCreatorProfile[item.dataField].length > 0 && TopCreatorProfile[item.dataField].join(', ') : TopCreatorProfile[item.dataField]}
            
              </p>
            </div>
          </div>
        ))}
    </FormModal>
  );
};

export default TopCreatorProfile;
