import React, { useState, useEffect } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";
import View from "./View";
import Play from "./Play";
import Status from "./Status";
import "./style.scss";
import Send from "./Send";
import UsersReport from "./UsersReport";

const Report = () => {
  const [reportList, setReportList] = useState({
    users: [],
    recordsTotal: 0,
  });
  const [loading, setLoading] = useState(false);
  const [singleReport, setSingleReport] = useState(null);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [playShowModal, setPlayShowModal] = useState(false);
  const [statusShowModal, setStatusShowModal] = useState(false);
  const [sendShowModal, setSendShowModal] = useState(false);
  const [filterObject, setFilterObject] = useState({
    page: 1,
    sizePerPage: 10,
    search: "",
  });
  const [countShowModal, setCountShowModal] = useState(false);
  const [countDetail, setCountDetail] = useState(null);

  const playVideo = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button className="btn btn-link" onClick={() => playClick(row)}>
            Play
          </button>
        </div>
      </>
    );
  };
  const playClick = (row) => {
    setSingleReport(row);
    setPlayShowModal(true);
  };

  const statusClick = (row) => {
    setSingleReport(row);
    setStatusShowModal(true);
  };
  const sendClick = (row) => {
    setSingleReport(row);
    setSendShowModal(true);
  };
  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button
            className="btn btn-link me-3"
            disabled={!row.type_id}
            onClick={() => statusClick(row)}
          >
            {row.is_disable ? (
              <i className="fa fa-toggle-on"></i>
            ) : (
              <i className="fa fa-toggle-off"></i>
            )}
          </button>
          <button className="btn btn-link me-3" onClick={() => sendClick(row)}>
            <i className="fa fa-envelope" aria-hidden="true"></i>
          </button>
          <button className="btn btn-link " onClick={() => viewClick(row)}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "creator_name",
      text: "Creator name",
      headerStyle: { width: "25%" },
    },
    {
      dataField: "no_of_reports",
      text: "No of reports",
      headerStyle: { width: "10%" },
    },
    {
      dataField: "interest_name",
      text: "Interest",
      headerStyle: { width: "20%" },
    },
    {
      dataField: "published_on",
      text: "Published on",
      headerStyle: { width: "17%" },
    },
    {
      dataField: "paid_on",
      text: "Video",
      formatter: playVideo,
      headerStyle: { width: "13%" },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
      headerStyle: { width: "15%" },
    },
  ];

  const getReportList = async () => {
    setLoading(true);
    let obj = {
      length: filterObject.sizePerPage,
      start: (filterObject.page - 1) * filterObject.sizePerPage,
      draw: filterObject.sizePerPage,
      role: "creator",
      search: {
        value: filterObject.search,
      },
    };
    try {
      const getData = await postData("/video-reported-list", {}, obj);
      if (getData && getData.status === 1) {
        setReportList(getData.data);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setFilterObject((prev) => ({
      ...prev,
      page,
      sizePerPage,
      search,
    }));
  };

  useEffect(() => {
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject.page, filterObject.sizePerPage, filterObject.search]);

  const viewClick = (row) => {
    setSingleReport(row);
    setViewShowModal(true);
  };

  const onViewClose = (status) => {
    if (status) getReportList();
    setSingleReport(null);
    setViewShowModal(false);
    setPlayShowModal(false);
    setStatusShowModal(false);
    setSendShowModal(false);
  };

  const countClick = (item) => {
    setCountDetail(item);
    setViewShowModal(false);
    setCountShowModal(true);
  };
  const countShowModalClose = () => {
    setCountDetail(null);
    setViewShowModal(true);
    setCountShowModal(false);
  };
  return (
    <div>
      <h4 className="title">Report</h4>
      {/* <ClientSideTable columns={columns} data={data} /> */}
      <ServerSideTable
        columns={columns}
        data={reportList.users}
        page={filterObject.page}
        sizePerPage={filterObject.sizePerPage}
        totalSize={reportList.recordsTotal}
        onFilter={onFilter}
        loading={loading}
      ></ServerSideTable>

      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          singleReport={singleReport}
          countClick={countClick}
        />
      )}
      {statusShowModal && (
        <Status
          show={statusShowModal}
          onClose={onViewClose}
          singleReport={singleReport}
        />
      )}
      {sendShowModal && (
        <Send
          show={sendShowModal}
          onClose={onViewClose}
          singleReport={singleReport}
        />
      )}
      {playShowModal && (
        <Play
          show={playShowModal}
          onClose={onViewClose}
          singleReport={singleReport}
        />
      )}
      {countShowModal && (
        <UsersReport
          show={countShowModal}
          onClose={countShowModalClose}
          countDetail={countDetail}
        />
      )}
    </div>
  );
};

export default Report;
