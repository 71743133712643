import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Status = ({ show, onClose, singleReport }) => {
  const [loading, setLoading] = useState(false);
  const saveClick = async () => {
    setLoading(true);
    let obj = {
      is_disable: singleReport.is_disable ? false : true,
      type_id: singleReport.type_id,
    };
    const res = await postData("/disable-reported-video", {}, obj);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  const cancelClick = () => {
    onClose(false);
  };
  return (
    <FormModal
      show={show}
      onClose={cancelClick}
      heading={singleReport.is_disable ? "Disable video" : "Enable video"}
    >
      <div className="form-group">
        <p>
          Are you sure you want to{" "}
          {singleReport.is_disable ? "disable" : "enable"} this video ?
        </p>
      </div>
      <div className="text-end">
        <Button
          type="button"
          className="btn-default text-blacksix me-3"
          onClick={cancelClick}
        >
          No
        </Button>
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Yes
        </Button>
      </div>
    </FormModal>
  );
};

export default Status;
