import React, { useState, useEffect } from "react";
import "./style.scss";
import logo from "../../assets/images/logo.png";
import { useParams } from "react-router-dom";
import { postData } from "../../api";
import LeftTop from "../../assets/images/left-top.png";
import LeftBottom from "../../assets/images/left-bottom.png";
import RightTop from "../../assets/images/right-top.png";
import RightBottom from "../../assets/images/right-bottom.png";
import Group from "../../assets/images/Group.png";
import GooglePlay from "../../assets/images/GooglePlay.png";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";

const CreatorOnboard = () => {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    validateToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateToken = async () => {
    setLoading(true);
    let obj = {
      secret_key: id,
    };
    try {
      const getData = await postData("/user-to-creator-onboard", {}, obj);
      if (getData && getData.status === 1) {
        setLoading(false);
        toast.success(getData.message, { theme: "colored" });
      } else {
        setLoading(false);
        toast.error(getData.message, { theme: "colored" });
      }
    } catch (err) {}
  };

  return (
    <section className="setpassword-section">
      <img
        className="position-absolute top-0 start-0"
        src={LeftTop}
        alt="crunch"
      />
      <img
        className="position-absolute bottom-0 start-0"
        src={LeftBottom}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 topcircle"
        src={RightTop}
        alt="crunch"
      />
      <img
        className="position-absolute end-0 bottomcircle"
        src={RightBottom}
        alt="crunch"
      />
      <div className="container">
        <div className="row">
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="login-box">
              <div className="content">
                <div className="img mb-5 text-center">
                  <img src={logo} alt="logo" />
                </div>
                {!loading && (
                  <>
                    <h5 className="pb-1 fw-700 text-center"> Awesome! </h5>
                    <p className="fw-500 f-16 mb-3 mt-1 text-center">
                      {" "}
                      Now that you are a creator{" "}
                    </p>
                    <h6 className="fw-600 f-17 mb-3 mt-5 text-center">
                      Go ahead and download the Crunch app to get started.
                    </h6>
                    <div className="d-flex mt-4 Applink">
                      <a
                        className="me-3"
                        href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada"
                      >
                        <img src={Group} alt="group" />
                      </a>
                      <a href="https://play.google.com/store/apps/details?id=com.trainerize.crunchcanada">
                        <img src={GooglePlay} alt="play" />
                      </a>
                    </div>
                  </>
                )}
                {loading && <Loader />}
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    </section>
  );
};

export default CreatorOnboard;
