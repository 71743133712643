import React from "react";
import FormModal from "../../components/Modal";
const Status = ({ show, onClose, singleCreator }) => {
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="Creator profile information"
      size="lg"
    >
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Name</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleCreator?.name}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Username</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleCreator?.user_name}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">Email</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleCreator?.email}</p>
        </div>
      </div>
      <div className="row form-group">
        <div className="col-md-4">
          <p className="f-14 fw-600 mb-0">About me</p>
        </div>
        <div className="col-md-1">
          <p className="f-14 fw-600 mb-0">:</p>
        </div>
        <div className="col-md-7">
          <p className="f-14 fw-400 mb-0">{singleCreator?.profile?.bio}</p>
        </div>
      </div>

    </FormModal>
  );
};

export default Status;
