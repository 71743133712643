import React, { useState } from "react";
import Button from "../../components/Form/Button";
import FormModal from "../../components/Modal";
import { postData } from "../../api";
import { toast } from "react-toastify";
const Status = ({ show, onClose, singleRow, role }) => {
  const [loading, setLoading] = useState(false);
  const saveClick = async () => {
    setLoading(true);
    let obj = {
      email: singleRow.email,
      role: role,
    };
    obj._id = singleRow._id;
    const res = await postData("/user-invite-resend", {}, obj);
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(true);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };

  return (
    <FormModal show={show} onClose={onClose} heading={"Resend invite"}>
      <div className="form-group">
        <p>Are you sure you want to resend invite ?</p>
      </div>
      <div className="text-end">
        <Button
          type="button"
          disabled={loading}
          loading={loading}
          onClick={saveClick}
          className="btn-primary text-white"
        >
          Resend
        </Button>
      </div>
    </FormModal>
  );
};

export default Status;
