import React from "react";
//image
import Logo from "../../assets/images/logo.png";
//style
import "./style.scss";

const Header = (props) => {
  return (
    <>
      <div className="header bg-color-primary">
        <div className="header-left">
          <img src={Logo} className="logo" alt="logo" />
        </div>
        <div className="header-right">
            <h5 className="mb-0 text-white text-center">Admin panel</h5>
        </div>
      </div>
    </>
  );
};

export default Header;
