import React from "react";
import { useState } from "react";
import FormModal from "../../components/Modal";
const View = ({ show, onClose, singleReport, countClick }) => {
  const [paymentDetail] = useState([
    {
      dataField: "creator_name",
      text: "Creator name",
    },
    {
      dataField: "no_of_reports",
      text: "No of reports",
    },
    {
      dataField: "interest_name",
      text: "Interest",
    },
    {
      dataField: "published_on",
      text: "Published on",
    },
  ]);
  const reportClick = (item) => {
    countClick(item);
  };
  return (
    <FormModal show={show} onClose={onClose} heading="Report details" size="lg">
      {paymentDetail &&
        paymentDetail.map((item, index) => (
          <React.Fragment key={index}>
            {singleReport && (
              <div className="row form-group">
                <div className="col-md-4">
                  <p className="f-14 fw-600 mb-0">{item.text}</p>
                </div>
                <div className="col-md-1">
                  <p className="f-14 fw-600 mb-0">:</p>
                </div>
                <div className="col-md-7">
                  <p className="f-14 fw-400 mb-0">
                    {singleReport[item.dataField]}
                  </p>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      {singleReport.reason_count && singleReport.reason_count.length && (
        <div className="row form-group">
          <div className="col-md-12">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Reason</th>
                  <th scope="col">No. of users reported</th>
                </tr>
              </thead>
              <tbody>
                {singleReport.reason_count &&
                  singleReport.reason_count.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>
                        <button
                          className="btn btn-link"
                          onClick={() => reportClick(item)}
                        >
                          {item.count}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </FormModal>
  );
};

export default View;
