import { useEffect, useState } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";
import Manage from "./Manage";
import Status from "./Status";
const Interest = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);

  const [interestList, setInterestList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addShowModal, setAddShowModal] = useState(false);
  const [statusShowModal, setStatusShowModal] = useState(false);

  const [singleInterest, setSingleInterest] = useState(null);
  const addClick = () => {
    setAddShowModal(true);
  };

  const EditClick = (row) => {
    setSingleInterest(row);
    setAddShowModal(true);
  };
  const statusClick = (row) => {
    setSingleInterest(row);
    setStatusShowModal(true);
  };

  const onAddClose = (status) => {
    if (status) getInterestList(page, sizePerPage);
    setSingleInterest(null);
    setAddShowModal(false);
    setStatusShowModal(false);
  };
  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button className="btn btn-link me-3" onClick={() => EditClick(row)}>
            <i className="fa fa-pencil"></i>
          </button>
          <button className="btn btn-link" onClick={() => statusClick(row)}>
            {row.active_status === 1 ? (
              <i className="fa fa-toggle-on"></i>
            ) : (
              <i className="fa fa-toggle-off"></i>
            )}
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Name",
      headerStyle: { width: "30%", textAlign: "left" },
      style:{ textAlign: "left",paddingLeft:"25px" }
    },
    {
      dataField: "user_follow_count",
      text: "No. of users following",
      headerStyle: { width: "25%" },
    },
    {
      dataField: "creator_follow_count",
      text: "No. of content creators following",
      headerStyle: { width: "25%" },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];
  
  const getInterestList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      role: "user",
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/manage-interest-list", {}, obj);
      if (getData && getData.status === 1) {
        setInterestList(getData.data.interest_list);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getInterestList(page, sizePerPage, search);
  };
  useEffect(() => {
    getInterestList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h4 className="title">Interest</h4>
      <ServerSideTable
        columns={columns}
        data={interestList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      >
        <div className="action-group text-end">
          <button
            className="btn f-14 fw-600 btn-sm text-white btn-primary"
            onClick={addClick}
          >
            Add new
          </button>
        </div>
      </ServerSideTable>
      {addShowModal && (
        <Manage
          show={addShowModal}
          onClose={onAddClose}
          singleInterest={singleInterest}
        />
      )}
      {statusShowModal && (
        <Status
          show={statusShowModal}
          onClose={onAddClose}
          singleInterest={singleInterest}
        />
      )}
    </div>
  );
};

export default Interest;
