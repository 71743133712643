import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import FormModal from "../../components/Modal";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import Button from "../../components/Form/Button";
import { postData } from "../../api";

const Create = ({ show, onClose }) => {
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectOptions = [
    { id: 0, lable: "Select all", name: "all" },
    { id: 1, lable: "Send all user", name: "user" },
    { id: 2, lable: "Send all creator", name: "creator" },
  ];
  const [notificationObject, setNotificationObject] = useState({
    title: "",
    message: "",
    to_send: "all",
  });
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNotificationObject((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true) {
      saveClick();
    }
  };

  const saveClick = async () => {
    setLoading(true);
    const res = await postData(
      "/add-custom-notification",
      {},
      notificationObject
    );
    if (res.status === 1) {
      setLoading(false);
      toast.success(res.message, { theme: "colored" });
      onClose(false);
    } else {
      toast.error(res.message, { theme: "colored" });
      setLoading(false);
    }
  };
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="Create notification"
      size="md"
      className="createNotify"
    >
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Input
          required
          label="Notification title"
          type="text"
          name="title"
          size="lg"
          errorMessage="title is required"
          onChange={handleInput}
        />
        <TextArea
          label="Message"
          name="message"
          size="lg"
          errorMessage="message is required"
          onChange={handleInput}
        />
        <div className="d-flex justify-content-between p-2 mb-3">
          {selectOptions.map((item, index) => {
            return (
              <Form.Check
                name="to_send"
                key={index}
                id={item.id}
                label={item.lable}
                value={item.name}
                type="radio"
                onChange={handleInput}
                checked={notificationObject?.to_send === item.name}
              />
            );
          })}
        </div>
        <div className="text-end">
          <Button
            type="button"
            className="btn-default text-blacksix me-3"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={loading}
            loading={loading}
            className="btn-primary text-white"
          >
            Send
          </Button>
        </div>
      </Form>
    </FormModal>
  );
};

export default Create;
