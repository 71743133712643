import { useEffect, useState } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { postData } from "../../api";

import View from "./View";
const TopCreatorList = () => {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizeperPage] = useState(10);
  const [totalSize, setTotalSize] = useState(0);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [singleTopCreatorProfile, setSingleTopCreatorProfile] = useState(null);
  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          <button
            className="btn btn-link fw-600"
            onClick={() => viewClick(row)}
          >
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      dataField: "sl.no",
      text: "S.no",
      headerStyle: { width: "10%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return  ((page-1)*sizePerPage)+(rowIndex+1);
      },
    },
    {
      dataField: "name",
      text: "Creator name",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "interest",
      text: "interest",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
      formatter: (row) => {
        console.log(row)
        return row?.length > 0 ? row?.join(', ').substring(0,15) + '...': '-';
      },
    },
    {
      dataField: "videos",
      text: "Video",
      headerStyle: { width: "25%", textAlign: "left" },
      style: { textAlign: "left", paddingLeft: "25px" },
    },
    {
      dataField: "followers",
      text: "Followers",
    },
    {
      dataField: "subscribers",
      text: "Paid subscribers",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: actionButton,
    },
  ];
  const getUsetList = async (page, sizePerPage, search) => {
    setLoading(true);
    let obj = {
      length: sizePerPage,
      start: (page - 1) * sizePerPage,
      draw: sizePerPage,
      search: {
        value: search,
      },
    };
    try {
      const getData = await postData("/top-creators-list", {}, obj);
      if (getData && getData.status === 1) {
        setUserList(getData.data.top_creators);
        setTotalSize(getData.data.recordsTotal);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setPage(page);
    setSizeperPage(sizePerPage);
    getUsetList(page, sizePerPage, search);
  };
  useEffect(() => {
    getUsetList(page, sizePerPage, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewClick = (row) => {
   setSingleTopCreatorProfile(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    setSingleTopCreatorProfile(null);
    setViewShowModal(false);
  };
  return (
    <div>
      <h4 className="title">Top creators</h4>
      <ServerSideTable
        columns={columns}
        data={userList}
        page={page}
        sizePerPage={sizePerPage}
        totalSize={totalSize}
        onFilter={onFilter}
        loading={loading}
      >
      </ServerSideTable>
    
      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          TopCreatorProfile={singleTopCreatorProfile}
        />
      )}
    </div>
  );
};

export default TopCreatorList;
