import React, { useState, useEffect, useRef } from "react";
import ServerSideTable from "../../components/ServerSideTable";
import { getData, postData } from "../../api";
import View from "./View";
import Datepicker from "../../components/Form/Datepicker";
import Select from "../../components/Form/Select";
import { CSVLink } from "react-csv";
import Button from "../../components/Form/Button";
import "./style.scss";

const PaymentReceive = () => {
  const csvLink = useRef();
  const [paymentList, setPaymentList] = useState({
    payments: [],
    recordsTotal: 0,
  });
  const [loading, setLoading] = useState(false);
  const [singlePaymentDetail, setSinglePaymentDetail] = useState(null);
  const [viewShowModal, setViewShowModal] = useState(false);
  const [selectRowId, setSelectRowId] = useState([]);
  const [createrList, setCreaterList] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [exportData, setExportData] = useState({
    data: [],
    headers: [],
  });
  const [isAllExport, setIsAllExport] = useState(0);
  const [filterObject, setFilterObject] = useState({
    page: 1,
    sizePerPage: 10,
    search: "",
    from: new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000),
    to: new Date(),
    creator: "",
  });
  const downloadClick = (row) => {
    window.open(row.hosted_invoice_url);
  };
  const actionButton = (cell, row) => {
    return (
      <>
        <div className="action-buttons">
          {/* {row.onboard_process === "Completed" && */}
          <button
            className="btn btn-link me-3"
            onClick={() => downloadClick(row)}
          >
            <i className="fa fa-download" aria-hidden="true"></i>
          </button>
          {/* } */}
          <button className="btn btn-link" onClick={() => viewClick(row)}>
            <i className="fa fa-eye"></i>
          </button>
        </div>
      </>
    );
  };
  const paidAmount = (cell, row) => {
    return (
      <>
        <div>&#36; {cell}</div>
      </>
    );
  };

  const selectRows = {
    mode: "checkbox",
    readOnly: true,
    selected: selectRowId,
    onSelect: (row, isSelect) => {
      setIsAllExport(0);
      if (isSelect) {
        setSelectRowId((prev) => [...prev, row._id]);
      } else {
        var index = selectRowId.indexOf(row._id);
        if (index !== -1) {
          const dup = [...selectRowId];
          dup.splice(index, 1);
          setSelectRowId([...dup]);
        }
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        rows.forEach((item) => {
          setSelectRowId((prev) => [...prev, item._id]);
        });
        setIsAllExport(1);
      } else {
        setIsAllExport(0);
        setSelectRowId([]);
      }
    },
  };

  const columns = [
    {
      dataField: "paid_by",
      text: "Paid by",
      headerStyle: { width: "25%", textAlign: "left" },
      style: {
        textAlign: "left",
        paddingLeft: "25px",
        textTransform: "capitalize",
      },
    },
    {
      dataField: "creator_name",
      text: "Creator name",
      headerStyle: { width: "25%", textAlign: "left" },
      style: {
        textAlign: "left",
        whiteSpace: "normal",
        textTransform: "capitalize",
        paddingLeft: "25px",
      },
    },
    {
      dataField: "amount_paid",
      text: "Paid amount",
      headerStyle: { width: "15%", textAlign: "left" },
      formatter: paidAmount,
    },
    {
      dataField: "paid_on",
      text: "Paid on",
      headerStyle: { width: "20%", textAlign: "left" },
      style: { textAlign: "left", whiteSpace: "normal", paddingLeft: "25px" },
    },
    {
      dataField: "action",
      text: "Action",
      headerStyle: { width: "15%" },
      formatter: actionButton,
    },
  ];
  const dateFormat = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    return `${yyyy}-${mm}-${dd}`;
  };
  const getCreaterList = async () => {
    try {
      const res = await getData("/active-creator-list", {});
      if (res && res.status === 1) {
        res.data.forEach((element) => {
          element.id = element._id;
        });
        setCreaterList(res.data);
      }
    } catch (err) {}
  };
  const getPaymentList = async () => {
    setLoading(true);
    let obj = {
      length: filterObject.sizePerPage,
      start: (filterObject.page - 1) * filterObject.sizePerPage,
      draw: filterObject.sizePerPage,
      role: "creator",
      from: dateFormat(filterObject.from),
      to: dateFormat(filterObject.to),
      creator: filterObject.creator,
      search: {
        value: filterObject.search,
      },
    };
    try {
      const getData = await postData("/payment-history", {}, obj);
      if (getData && getData.status === 1) {
        setPaymentList(getData.data);
        setLoading(false);
      }
    } catch (err) {}
  };
  const onFilter = (page, sizePerPage, search) => {
    setFilterObject((prev) => ({
      ...prev,
      page,
      sizePerPage,
      search,
    }));
    
  };
  useEffect(() => {
    getCreaterList();
  }, []);
  useEffect(() => {
    getPaymentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterObject.page,
    filterObject.sizePerPage,
    filterObject.search,
    filterObject.from,
    filterObject.to,
    filterObject.creator,
  ]);

  const viewClick = (row) => {
    setSinglePaymentDetail(row);
    setViewShowModal(true);
  };
  const onViewClose = (status) => {
    if (status) getPaymentList();
    setSinglePaymentDetail(null);
    setViewShowModal(false);
  };
  const filterChange = (e, which) => {
    console.log(e)
    let value = which === "creator" ? e.target.value : e;
    setFilterObject((x) => ({
      ...x,
      page: 1,
      sizePerPage: 10,
      search: "",
      [which]: value,
    }));
    console.log(filterObject)
  };
  const exportClick = async () => {
    setExportLoading(true);
    let obj = {
      payment_id: isAllExport === 0 ? selectRowId : [],
      is_all: isAllExport,
    };
    try {
      const getData = await postData("/payment-export", {}, obj);
      if (getData && getData.status === 1) {
        setExportData(getData.data);
        setTimeout(() => {
          csvLink.current.link.click();
          setExportLoading(false);
        }, 1000);
        setSelectRowId([]);
      }
      setExportLoading(false);
    } catch (err) {
      setExportLoading(false);
    }
  };

  return (
    <div>
      <h4 className="title">Payment</h4>
      {/* <ClientSideTable columns={columns} data={data} /> */}
      <ServerSideTable
        columns={columns}
        data={paymentList.payments}
        page={filterObject.page}
        sizePerPage={filterObject.sizePerPage}
        totalSize={paymentList.recordsTotal}
        onFilter={onFilter}
        loading={loading}
        selectRow={selectRows}
        filterOption={true}
      >
        <div className="action-group row">
          <div className="col-md-4">
            <Datepicker
              label="From"
              name="dob"
              value={filterObject.from}
              errorMessage="From Date is not valid"
              maxDate={filterObject.to}
              onChange={(date) => filterChange(date, "from")}
              placeholder="Select your From Date"
            ></Datepicker>
          </div>
          <div className="col-md-4">
            <Datepicker
              label="To"
              name="dob"
              value={filterObject.to}
              minDate={filterObject.from}
              maxDate={new Date()}
              errorMessage="To Date is not valid"
              onChange={(date) => filterChange(date, "to")}
              placeholder="Select your To Date"
            ></Datepicker>
          </div>
          <div className="col-md-4">
            <Select
              name="creator"
              label="Creator"
              value={filterObject.creator}
              options={createrList}
              size="lg"
              onChange={(e) => filterChange(e, "creator")}
            ></Select>
          </div>
          <div className="col-md-12 text-end">
            <Button
              type="button"
              className="btn f-14 fw-600 btn-sm text-white btn-primary"
              onClick={exportClick}
              loading={exportLoading}
              disabled={exportLoading || selectRowId.length === 0}
            >
              Export CSV
            </Button>
            <CSVLink
              data={exportData.data}
              headers={exportData.headers}
              filename={"payment.csv"}
              className="d-none"
              ref={csvLink}
            >
              {exportLoading ? "Loading csv..." : "Export CSV"}
            </CSVLink>
          </div>
        </div>
      </ServerSideTable>

      {viewShowModal && (
        <View
          show={viewShowModal}
          onClose={onViewClose}
          singlePaymentDetail={singlePaymentDetail}
        />
      )}
    </div>
  );
};

export default PaymentReceive;
