import React from "react";
import { useState } from "react";
import FormModal from "../../components/Modal";
const Status = ({ show, onClose, singlePaymentDetail }) => {
  const [paymentDetail] = useState([
    {
      text: "Paid by",
      dataField: "paid_by",
    },
    {
      text: "Paid for",
      dataField: "paid_by_email",
    },
    {
      text: "Creator name",
      dataField: "creator_name",
    },
    {
      text: "Paid amount",
      dataField: "amount_paid",
    },
    {
      text: "Paid on",
      dataField: "paid_on",
    },
  ]);
  return (
    <FormModal
      show={show}
      onClose={onClose}
      heading="Payment details"
      size="lg"
    >
      {paymentDetail &&
        paymentDetail.map((item, index) => (
          <div key={index} className="row form-group">
            <div className="col-md-4">
              <p className="f-14 fw-600 mb-0">{item.text}</p>
            </div>
            <div className="col-md-1">
              <p className="f-14 fw-600 mb-0">:</p>
            </div>
            <div className="col-md-7">
              <p className="f-14 fw-400 mb-0 text-capitalize">
                {item.dataField === "amount_paid" ? <span>&#36;</span> : null}
                {singlePaymentDetail[item.dataField]}
              </p>
            </div>
          </div>
        ))}
    </FormModal>
  );
};

export default Status;
